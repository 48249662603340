// Read the user object and extracts permissions to the FE related only,
// with the following format: [${ability}-${entity}]

export const extractPermissions = (user) => {
  if (!user?.permissions) return []

  const allowedNames = [
    'pp.finance.invoice.list',
    'pp.finance.invoice.show',
    'pp.finance.invoice.update_billing_details',
    'pp.finance.airalo-credits.add',
    'pp.finance.airalo-credits-summary.show',
    'show-api-credentials',
    'buy-esims',
    'buy-topups',
    'list',
    'show',
    'edit',
    'add',
    'share',
    'usage',
    'assign-create',
    'assign-update',
    'insights-dashboard',
    'pp.packages.download-csv',
    'pp.packages.retail-price-download-csv',
    'pp.packages.reseller-price-download-csv',
    'pp.company.domains.verify',
    'pp.company.domains.create',
    'pp.company.saml.update',
    'pp.company.saml.create',
    'pp.company.saml.show',
    'pp.company.saml.test',
    'pp.companies.branches.list',
    'pp.companies.business_units.show',
    'pp.companies.business_units.add',
    'pp.companies.business_units.update',
    'pp.companies.business_units.delete',
    'pp.company.bill_to_organization.manage',
    'pp.company.view-details',
    'pp.company.update-details',
    'pp.company.view-billing-details',
    'pp.company.update-billing-details',
  ]

  return user.permissions
    .filter((permission) => allowedNames.includes(permission.name))
    .map((permission) => {
      if (!permission.entity) return permission.name
      return `${permission.name}-${permission.entity}`
    })
}
