import type {
  BranchDetails,
  BranchResponse,
  BranchDetailsWithChildren,
} from '../types/branchTypes'

export function buildTreeStructure(
  items: BranchDetails[],
  flatten: boolean = false
): BranchDetailsWithChildren[] {
  const itemMap = new Map<number, BranchDetailsWithChildren>(
    items.map((item): [number, BranchDetailsWithChildren] => [
      item.id,
      { ...item, children: [] },
    ])
  )

  const roots: BranchDetailsWithChildren[] = []

  items.forEach((item) => {
    const currentItem = itemMap.get(item.id)

    if (currentItem) {
      if (item.parent_id === null || !itemMap.has(item.parent_id)) {
        roots.push(currentItem)
      } else {
        const parentItem = itemMap.get(item.parent_id)
        if (parentItem) {
          parentItem.children.push(currentItem)
        }
      }
    }
  })

  if (flatten) {
    const flatArray: BranchDetailsWithChildren[] = []

    const addToFlatArray = (branch: BranchDetailsWithChildren) => {
      flatArray.push(branch)
      branch.children.forEach((child) => addToFlatArray(child))
    }

    roots.forEach((root) => addToFlatArray(root))
    return flatArray
  }

  return roots
}

export function transformBranchResponse({
  data,
}: {
  data: BranchResponse
}): BranchDetails {
  return data
}

export function transformBranchesResponse(
  data: BranchResponse[],
  flatten: boolean = false
): BranchDetailsWithChildren[] {
  const idMapping: Record<number, BranchDetails> = data.reduce((acc, item) => {
    acc[item.id] = { ...item, level: 0 }
    return acc
  }, {} as Record<number, BranchDetails>)

  const calculateLevel = (branch: BranchDetails): number => {
    if (branch.parent_id === null || !idMapping[branch.parent_id]) return 0

    const parent = idMapping[branch.parent_id]
    if (parent) {
      if (parent.level === 0 && parent.parent_id !== null) {
        parent.level = calculateLevel(parent)
      }
      return (parent.level ?? 0) + 1
    }
    return 0
  }

  const processedData = Object.values(idMapping).map((branch) => {
    branch.level = calculateLevel(branch)
    return branch
  })

  return buildTreeStructure(processedData, flatten)
}
