<template>
  <div class="grid gap-4">
    <UsersAddBulkTableView
      v-if="showAddBulkUsers"
      :bulkUsers="bulkUsers"
      @hideBulkUsers="showAddBulkUsers = false"
      @openBulkAddUsersModal="showUserAddBulkModal = true"
      @removeBulkUserItem="removeBulkUserItem"
      @fetchUsers="fetchData"
    />

    <template v-else>
      <BillToOrganizationBanner
        v-if="isCorporateModeOn && canViewSpendingSettings"
        :loading="companyDataLoading"
        :company-data="companyData"
      />

      <h3 class="text-trail-title2 text-trail-text-title-normal">
        {{ $t('userPageTitle') }}
        <span v-if="paginationData?.total">({{ paginationData.total }})</span>
      </h3>

      <div class="grid gap-4">
        <div
          class="flex items-center z-30 justify-between"
          :class="{ '!items-start flex-col gap-4': width < 850 }"
        >
          <UserFilters @filters-changed="onFiltersChanged" />

          <UserForm
            v-if="permissionsStore.canAddUser"
            :fetch-users="fetchData"
            :selected-users="selectedRows"
            :isSSOEnabled="isSSOEnabled"
            :company-bill-to-org="companyData?.bill_to_organization"
            @clear-selected-users="clearSelectedUsers"
            @fill-bulk-add-user="fillBulkAddUser"
          />
        </div>

        <TrailBanner
          v-if="isSSOEnabled"
          class="mb-2"
          type="warning"
          data-testid="sso-banner"
        >
          {{ $t('ssoBannerText') }}
        </TrailBanner>
      </div>

      <UsersTable
        v-model:selectedRows="selectedRows"
        :rows="userList"
        :is-loading="loading"
        :searchQuery="filters.search"
        :pagination="paginationData"
        :company-bill-to-org="companyData?.bill_to_organization"
        @pagination-changed="pageChanged"
        @update:reload-table="fetchData"
      />
    </template>

    <UserAddBulkModal
      v-if="showUserAddBulkModal"
      :show="showUserAddBulkModal"
      @close="showUserAddBulkModal = false"
      @fill-bulk-user="fillBulkUser"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useApiData } from '@/composables/useApiData'
import { useSSOConfiguration } from '@/composables/sso/use-sso-configuration'
import type { UserAddBulkDataType, UserFilterType } from '@/models/userModels'
import { usePermissionsStore } from '@/stores/permissions'
import { transformUserListResponse } from '@/api/transforms/userTransforms.ts'
import { useWindowSize } from '@vueuse/core'
import { useAuthStore } from '@/stores/auth'
import { useFeatureFlagsStore } from '@/stores/feature-flags.ts'
import { storeToRefs } from 'pinia'
import type { CompanyData } from '@/models/companyModels.ts'

definePage({
  name: 'users',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\User\\Models\\User'],
  },
})

const { canViewSpendingSettings } = storeToRefs(usePermissionsStore())
const bulkUsers = ref<UserAddBulkDataType[]>([])
const selectedRows = ref<[]>([])
const { isSSOEnabled } = useSSOConfiguration()
const currentPage = ref(1)
const showAddBulkUsers = ref(false)
const { width } = useWindowSize()
const permissionsStore = usePermissionsStore()
const showUserAddBulkModal = ref(false)

const authStore = useAuthStore()
const featureFlagsStore = useFeatureFlagsStore()
const isCorporateModeOn = computed(
  () => featureFlagsStore?.corporateMode && authStore?.isOnlyCorporateStream
)

const filters = ref<UserFilterType>({
  search: '',
  status: [],
  branches: [],
})

const { data: companyData, loading: companyDataLoading } =
  useApiData<CompanyData>(`/company/v1/company`, { immediate: true })

const {
  data: userListResponse,
  fetchData,
  loading,
} = useApiData('/user/v1/users', {
  transformer: transformUserListResponse,
  params: () => ({
    'filter[search]': filters.value.search,
    'filter[status]': filters.value.status.join(','),
    'filter[company_ids]': filters.value.branches.join(','),
    page: currentPage.value,
    limit: 25,
  }),
  immediate: false,
})

const pageChanged = (page: number) => {
  selectedRows.value = []
  currentPage.value = page
  fetchData()
}

const clearSelectedUsers = (isFetchData: boolean = true) => {
  selectedRows.value = []

  if (isFetchData) fetchData()
}
const fillBulkUser = (data: UserAddBulkDataType[]) => {
  bulkUsers.value = [...bulkUsers.value, ...data].map((item, key) => ({
    ...item,
    key,
  }))
}

const removeBulkUserItem = (key: number) => {
  bulkUsers.value = bulkUsers.value.filter((item) => item.key !== key)
}

const fillBulkAddUser = (data: UserAddBulkDataType[]) => {
  showAddBulkUsers.value = true
  bulkUsers.value = data
}

const onFiltersChanged = (updatedFilters: UserFilterType) => {
  filters.value = updatedFilters
  fetchData()
}

const userList = computed(() => userListResponse.value?.data ?? [])
const paginationData = computed(() => userListResponse.value?.meta)
</script>
