<template>
  <div class="grid gap-y-6">
    <h3 class="text-trail-title1 text-trail-text-title-normal">
      {{ $t('analyticsPageTitle') }}
    </h3>

    <div class="flex gap-4">
      <DateRangeFilter
        v-model:selected-option="selectedOption"
        v-model:date-from="dateFrom"
        v-model:date-to="dateTo"
        button-type="cta"
        data-testid="dashboardFiltersDateRange"
      />

      <TrailDropdown
        :model-value="type"
        :label="
          $t('typeFilterLabel', {
            type: typeFilterOptions.find((item) => item.value === type)?.label,
          })
        "
        :options="typeFilterOptions"
        data-testid="dashboardFiltersPurchaseType"
        @update:model-value="type = $event"
      />
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-10 gap-6">
      <component
        :is="component"
        v-for="(
          { component, className, dataTestId }, index
        ) in dashboardWidgets"
        :key="index"
        :selected-option="selectedOption"
        :date-from="dateFrom"
        :date-to="dateTo"
        :type="type"
        :class="className"
        :data-testid="dataTestId"
        @update-date-range="handleUpdateDateRange"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DashboardOverviewCard from '@/components/Dashboard/DashboardOverviewCard.vue'
import DashboardOverallPackages from '@/components/Dashboard/Widgets/DashboardOverallPackages.vue'
import DashboardAssignedEsims from '@/components/Dashboard/Widgets/DashboardAssignedEsims.vue'
import DashboardTotalDiscounts from '@/components/Dashboard/Widgets/DashboardTotalDiscounts.vue'
import DashboardDashboardCarbonFootprintReduction from '@/components/Dashboard/Widgets/DashboardCarbonFootprintReduction.vue'
import DashboardPackageSizeDistribution from '@/components/Dashboard/Widgets/DashboardPackageSizeDistribution.vue'
import DashboardSimDistributionMap from '@/components/Dashboard/Widgets/DashboardSimDistributionMap.vue'
import DashboardTopBuyers from '@/components/Dashboard/Widgets/DashboardTopBuyers.vue'
import DashboardTopEsimUsers from '@/components/Dashboard/Widgets/DashboardTopEsimUsers.vue'
import { useFeatureFlagsStore } from '@/stores/feature-flags.ts'
import { useAuthStore } from '@/stores/auth'
import DashboardBranchesRevenueSales from '@/components/Dashboard/Widgets/DashboardBranchesRevenueSales.vue'

definePage({
  name: 'analytics',
  meta: { requiresAuth: true, requiredPermissions: ['insights-dashboard'] },
})

const { t } = useI18n()
const savedFilters = localStorage.getItem('dashboardFilters')
const parsedFilters = savedFilters
  ? (JSON.parse(savedFilters) as Record<string, string>)
  : undefined

const selectedOption = ref(parsedFilters?.selectedOption ?? 'Last30Days')
const dateFrom = ref(parsedFilters?.dateFrom ?? '')
const dateTo = ref(parsedFilters?.dateTo ?? '')
const type = ref(parsedFilters?.type ?? '')

const authStore = useAuthStore()
const featureFlagsStore = useFeatureFlagsStore()
const isCorporateModeOn = computed(
  () => featureFlagsStore?.corporateMode && authStore?.isOnlyCorporateStream
)

const saveDashboardFilters = () => {
  const dashboardFilters = {
    selectedOption: selectedOption.value,
    type: type.value,
    ...(selectedOption.value === 'Custom'
      ? {
          dateFrom: dateFrom.value,
          dateTo: dateTo.value,
        }
      : {}),
  }
  localStorage.setItem('dashboardFilters', JSON.stringify(dashboardFilters))
}

const handleUpdateDateRange = (
  newSelectedOption: unknown,
  newDateFrom: unknown,
  newDateTo: unknown
) => {
  selectedOption.value = newSelectedOption as string
  dateFrom.value = newDateFrom as string
  dateTo.value = newDateTo as string
}

const dashboardWidgets = [
  {
    component: DashboardOverviewCard,
    className: 'sm:col-span-2 lg:col-span-7 row-span-4 min-h-155 lg:min-h-0',
    dataTestId: 'dashboardOverviewCard',
  },
  {
    component: DashboardOverallPackages,
    className: 'lg:col-span-3',
    dataTestId: 'dashboardOverallPackages',
  },
  {
    component: DashboardAssignedEsims,
    className: 'lg:col-span-3',
    dataTestId: 'dashboardAssignedEsims',
  },
  {
    component: DashboardTotalDiscounts,
    className: 'lg:col-span-3',
    dataTestId: 'dashboardTotalDiscounts',
  },
  {
    component: DashboardDashboardCarbonFootprintReduction,
    className: 'lg:col-span-3',
    dataTestId: 'dashboardCarbonFootprintReduction',
  },
  {
    component: DashboardPackageSizeDistribution,
    className: 'lg:col-span-4',
    dataTestId: 'dashboardPackageSizeDistribution',
  },
  isCorporateModeOn.value && {
    component: DashboardTopEsimUsers,
    className: 'lg:col-span-6 self-start',
    dataTestId: 'dashboardTopEsimUsers',
  },
  {
    component: DashboardBranchesRevenueSales,
    className:
      'sm:col-span-2 lg:col-span-6 row-span-2 order-1 lg:order-none self-start',
    dataTestId: 'dashboardBranchesRevenueSales',
  },
  !isCorporateModeOn.value && {
    component: DashboardTopBuyers,
    className: 'lg:col-span-4 self-start',
    dataTestId: 'dashboardTopBuyers',
  },
  {
    component: DashboardSimDistributionMap,
    className:
      'sm:col-span-2 lg:col-span-12 row-span-2 order-1 lg:order-none self-start',
    dataTestId: 'dashboardSimDistributionMap',
  },
]

const typeFilterOptions = [
  { label: t('typeFilterAll'), value: '' },
  { label: t('typeFiltereSIM'), value: 'ESIM' },
  { label: t('typeFilterTopup'), value: 'TOPUP' },
]

watch([selectedOption, dateFrom, dateTo, type], () => {
  saveDashboardFilters()
})
</script>
