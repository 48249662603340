<template>
  <AuthPagesLayout>
    <SignInSSOForm />
  </AuthPagesLayout>
</template>

<script setup lang="ts">
definePage({
  meta: { requiresAuth: false },
  name: 'sign-in-sso',
})
</script>
