<template>
  <div class="flex flex-col gap-4">
    <BillingPageHeader
      v-model:status-filter="statusFilter"
      v-model:branches-filter="branchesFilter"
      :display-branch-filter="companyStore.hasMultipleBranches"
    />

    <BillingTable
      v-model:page="page"
      v-model:sort="sort"
      :display-branch-column="companyStore.hasMultipleBranches"
      :rows="billingTableData"
      :loading="billingTableResponseLoading"
      :total-items="totalItems"
    />
  </div>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'
import type { Sort } from '@/components/Trail/TrailDataTable.vue'
import type { BillingTableResponse } from '@/models/billingModels'
import { useAuthStore } from '@/stores/auth'
import { useCompanyStore } from '@/stores/company.store'

definePage({
  name: 'billing',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.finance.invoice.list'],
  },
})

const authStore = useAuthStore()
const companyStore = useCompanyStore()

const page = ref(1)
const totalItems = ref<number>()

const serializedSort = useLocalStorage<{ sort: string }>('billingSort-v2', {
  sort: '-created_at',
})

const sort = computed({
  get: () =>
    ({
      order: serializedSort.value.sort.startsWith('-') ? 'DESC' : 'ASC',
      sortBy: serializedSort.value.sort.split('-').at(-1),
    }) as const,
  set: (sort?: Sort) => {
    const { order, sortBy } = sort ?? { sortBy: 'created_at', order: 'DESC' }
    const sortOrder = order === 'DESC' ? '-' : ''
    serializedSort.value = { sort: `${sortOrder}${sortBy}` }
  },
})

const statusFilter = ref()
const branchesFilter = ref()

const queryParams = computed(() => ({
  page: page.value,
  sort: serializedSort.value.sort,
  'filter[status]': statusFilter.value?.status,
  'filter[company_ids]': branchesFilter.value?.branches?.join(','),
}))

const {
  fetchData: fetchBillingTableResponse,
  data: billingTableResponse,
  loading: billingTableResponseLoading,
} = useApiData<BillingTableResponse>(`finance/v1/invoices`, {
  params: queryParams,
  done: () => {
    totalItems.value = billingTableResponse.value?.meta.total
  },
  refetch: {
    watch: () => [authStore?.userCurrency, queryParams.value],
  },
  immediate: false,
})
const billingTableData = computed(() => billingTableResponse.value?.data ?? [])

const route = useRoute()
onMounted(() => {
  const queryStatus = route.query.status
  if (queryStatus === undefined) {
    fetchBillingTableResponse()
    return
  }

  const statusesArray = Array.isArray(queryStatus) ? queryStatus : [queryStatus]
  statusFilter.value = {
    status: statusesArray.filter(
      (status) =>
        status !== null && !Number.isNaN(+status) && 0 < +status && +status < 5
    ),
  }
})
</script>
