<template>
  <div
    class="max-w-500 mt-60 px-2 lg:w-1/3"
    data-testid="onboarding-already-registered-page"
  >
    <OnboardingMessage
      :title="$t('onboardingAlreadyRegisteredMessageTitle')"
      :description="alreadyRegisteredDescription"
      :image="EmailImage"
      data-testid="onboarding-message"
    >
      <template #actions>
        <NewButton
          type="secondary"
          class="h-12 w-full"
          data-testid="back-button"
          @click="onBack"
        >
          {{ $t('back') }}
        </NewButton>
      </template>
    </OnboardingMessage>
  </div>
</template>

<script setup lang="ts">
import EmailImage from '@/assets/images/svg/email.svg?component'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

definePage({
  name: 'already-registered',
})

const { t } = useI18n({ useScope: 'global' })

const { push, currentRoute } = useRouter()

const alreadyRegisteredDescription = computed<string>(() =>
  t('onboardingAlreadyRegisteredMessageDescription', {
    email: currentRoute.value.query.email?.toString(),
  })
)

const onBack = () => push('/sign-up/onboarding')
</script>
