<template>
  <div class="grid gap-6 px-6" data-testid="addCreditsContainer">
    <div class="flex flex-col gap-2.5">
      <router-link class="w-8 h-8 active:opacity-70" :to="{ name: 'credits' }">
        <BaseIcon
          icon="heroicons-outline:arrow-left"
          :size="24"
          class="cursor-pointer h-8 rounded-3xl bg-seashell-100 border border-seashell-900 text-grey-500 flex items-center justify-center"
        />
      </router-link>

      <h3 class="text-grey-800">{{ $t('addCreditsPageTitle') }}</h3>
    </div>

    <div class="flex gap-6">
      <AddCreditsChooseAmount
        :rounded-amount="roundedAmount"
        :default-amounts="defaultAmounts"
        :min-amount="minAmount"
        :is-amount-too-low="isAmountTooLow"
        :loading="loadingAddCreditsAmountsResponse"
        @set-amount="handleSetAmount"
      />

      <AddCreditsPaymentSummary
        :amount="amount"
        :default-amounts="defaultAmounts"
        :rounded-amount="roundedAmount"
        :is-amount-too-low="isAmountTooLow"
        @set-rounded-amount="handleSetRoundedAmount"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import { useApiData } from '@/composables/useApiData'
import type { AddCreditsAmountsResponse } from '@/models/financeModels'
import type { AmountType } from '@/models/currencyModels'
import { useAuthStore } from '@/stores/auth'

definePage({
  name: 'add-credits',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.finance.airalo-credits.add'],
  },
})

const authStore = useAuthStore()
const { t } = useI18n()
const { negative } = useTrailToast()

const {
  data: addCreditsAmountsResponse,
  loading: loadingAddCreditsAmountsResponse,
  error: addCreditsAmountsResponseError,
} = useApiData<AddCreditsAmountsResponse>('finance/v1/credits', {
  refetch: {
    watch: () => authStore.userCurrency,
  },
})
const defaultAmounts = computed(
  () => addCreditsAmountsResponse.value?.amounts || []
)
const minAmount = computed(() => addCreditsAmountsResponse.value?.min_amount)

const amount = ref<AmountType>({
  amount: 0,
  currency: authStore.userCurrency,
})
const isAmountTooLow = computed(
  () => !minAmount.value || amount.value.amount < minAmount.value.amount
)

const handleSetAmount = (value: number) => {
  amount.value = {
    amount: value,
    currency: authStore.userCurrency,
  }
}

const roundedAmount = ref<AmountType>({
  amount: 0,
  currency: authStore.userCurrency,
})

const handleSetRoundedAmount = (value: number) => {
  roundedAmount.value = {
    amount: value,
    currency: authStore.userCurrency,
  }
}

watch(addCreditsAmountsResponseError, (error) => {
  if (!error) {
    return
  }

  negative({
    content: t('errorTitle'),
  })
})
</script>
