<template>
  <AuthPagesLayout>
    <h3 class="text-trail-display2 text-trail-text-display-normal">
      {{ $t('resetTitle') }}
    </h3>

    <Reset />
  </AuthPagesLayout>
</template>

<script setup lang="ts">
definePage({
  name: 'reset-password',
})
</script>
