<template>
  <User />
</template>

<script setup>
definePage({
  name: 'user-detail',
  meta: { requiresAuth: true },
})
</script>
