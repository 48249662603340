<template>
  <div class="grid gap-y-4">
    <h3 class="text-grey-500" data-testid="creditsPageTitle">
      {{ $t('creditsPageTitle') }}
    </h3>

    <div class="grid items-start lg:grid-cols-[1fr_auto] grid-cols-1 gap-6">
      <BalanceCard
        v-if="permissionsStore.canViewBalanceSummary"
        ref="balanceCardRef"
        class="lg:order-1"
      />

      <BaseTable
        data-testid="creditsBillingHistoryTable"
        class="overflow-y-auto"
        :columns="[
          {
            name: $t('creditsPageColumnReferenceTitle'),
            key: 'referenceIdShort',
            customHeaderClass: 'w-36',
          },
          {
            name: $t('creditsPageColumnDateTitle'),
            key: 'createdAt',
            customHeaderClass: 'w-72',
          },
          {
            name: $t('creditsPageColumnUserNameTitle'),
            key: 'user',
            customHeaderClass: 'w-48',
            customClass: 'max-w-48 truncate',
          },
          {
            name: $t('creditsPageColumnCategoryTitle'),
            key: 'category',
            customHeaderClass: 'w-48',
          },
          {
            name: $t('creditsPageColumnAmountTitle'),
            key: 'convertedAmount',
            customHeaderClass: 'w-64 text-end',
            customClass: 'flex justify-end',
          },
        ]"
        :rows="transformedBillingHistoryData"
        :loading="loading"
      >
        <template #referenceIdShort-loader>
          <RectangleSkeleton class="w-36 h-5 rounded-full" />
        </template>

        <template #category="{ row }">
          <BalanceCategoryBadge
            class="relative"
            :category="row.category"
            :status="row.status"
            :reference="row.referenceTitle"
            :description="row.description"
          >
            <template v-if="row.status === BillingHistoryStatus.Pending" #icon>
              <BaseIcon
                class="absolute -bottom-1 -right-1 text-ultra-marine-blue-600"
                icon="heroicons-solid:clock"
                :size="20"
              />
            </template>
          </BalanceCategoryBadge>
        </template>

        <template #category-loader>
          <RectangleSkeleton class="w-20 h-8 rounded-full" />
        </template>

        <template #createdAt="{ row }">
          <p class="text-base text-grey-800">
            {{ row.createdAt }}
          </p>
        </template>

        <template #createdAt-loader>
          <RectangleSkeleton class="w-56 h-5 rounded-full" />
        </template>

        <template #convertedAmount="{ row }">
          <div
            :class="[
              'whitespace-nowrap',
              {
                'text-success-500':
                  row.type === BillingType.Credit &&
                  row.status !== BillingHistoryStatus.Failed,
              },
            ]"
          >
            {{ row.convertedAmount }}
          </div>
        </template>

        <template #convertedAmount-loader>
          <RectangleSkeleton class="w-20 h-5 rounded-full" />
        </template>

        <template #user-loader>
          <RectangleSkeleton class="w-44 h-5 rounded-full" />
        </template>

        <template #pagination>
          <div
            v-if="billingHistoryData?.meta.total"
            class="py-4 px-3 flex justify-start"
            :class="{
              'pointer-events-none opacity-50': loading,
            }"
          >
            <BasePagination
              data-testid="creditsBillingHistoryPagination"
              :total="billingHistoryData.meta.total"
              :current="currentPage"
              :per-page="billingHistoryData.meta.per_page"
              :pageRange="billingPerPage"
              @page-changed="pageChanged"
            >
              >
            </BasePagination>
          </div>
        </template>
      </BaseTable>
    </div>

    <AddCreditsPaymentSuccessModal />

    <MoneyTransferPaymentSuccessModal />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'
import { BillingHistoryStatus, BillingType } from '@/models/financeModels'
import { useApiData } from '@/composables/useApiData'
import { transformBillingHistoryResponse } from '@/api/transforms/financeTransforms'
import { usePermissionsStore } from '@/stores/permissions'
import { useAuthStore } from '@/stores/auth'
import type { AblyChannel, AblyMessageType } from '@/api/types/ablyTypes.ts'

definePage({
  name: 'credits',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Finance\\Models\\AccountTransaction'],
  },
})

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const permissionsStore = usePermissionsStore()
const billingPerPage = 5
const currentPage = ref<number>(parseInt(<string>route?.query?.page) || 1)
const balanceCardRef = ref<{
  refreshBalance: () => Promise<void>
}>()

const {
  data: billingHistoryData,
  fetchData,
  loading,
} = useApiData(`finance/v1/billing`, {
  transformer: transformBillingHistoryResponse,
  params: () => ({
    page: currentPage.value,
  }),
  refetch: { watch: () => authStore.userCurrency },
})

const pageChanged = (page: number) => {
  currentPage.value = page
  router.push({ query: { ...route.query, page } })
  fetchData()
}

const transformedBillingHistoryData = computed(() => {
  return (
    billingHistoryData?.value?.data.map((item) => {
      if (item.status === BillingHistoryStatus.Pending) {
        item = {
          ...item,
          customRowClass: 'opacity-50 pointer-events-none',
          description: '',
        }
      }
      return item
    }) ?? []
  )
})

const subscribeAbly = async () => {
  const ablyChannel = (await authStore.ablyChannel) as AblyChannel

  ablyChannel.subscribe(
    'finance.transaction.status.changed',
    async function (message: AblyMessageType) {
      if (message.data.status === 'completed') {
        await fetchData()
        await balanceCardRef?.value?.refreshBalance()
      }
    }
  )
}
onMounted(async () => {
  await subscribeAbly()
})
</script>
