<template>
  <div class="flex flex-col gap-8">
    <OrderPageHeader
      v-model:search-query="searchQuery"
      v-model:dates="selectedDates"
      v-model:filters="selectedFilters"
      v-model:branches-filter="branchesFilter"
      :order-page-title="t('apiOrderPageTitle')"
      :filters-options="filtersOptions"
      :loading="loading"
      :pagination-data="paginationData"
      :selected-rows="selectedRows"
      :export-selected-limit="exportSelectedLimit"
      :export-all-limit="exportAllLimit"
      :order-filters="orderFilters"
      order-type="api"
      data-testid="apiOrdersPageHeader"
    />

    <OrderTable
      v-model:selected-rows="selectedRows"
      :max-selected-rows="exportSelectedLimit"
      :rows="orderList"
      :is-loading="loading"
      :pagination="paginationData"
      order-type="api"
      data-testid="apiOrdersTable"
      @pagination-changed="pageChanged"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { transformApiOrderListResponse } from '@/api/transforms/ordersTransforms'
import { useAuthStore } from '@/stores/auth'
import type { TrailFilterOption } from '@/components/Trail/TrailFilter.vue'
import type { DateRange } from '@/components/Trail/TrailDateRangePicker.vue'

definePage({
  name: 'api-orders',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Package\\Models\\Order'],
  },
})

const authStore = useAuthStore()
const { t } = useI18n()

const selectedRows = ref<[]>([])
const exportSelectedLimit = 15000
const exportAllLimit = 15000

const filtersOptions = computed<TrailFilterOption[]>(() => [
  {
    key: 'packages',
    label: t('orderFilterPackageTitle'),
    variant: 'CheckboxLabel',
    props: {
      options: [
        { value: 'topups', label: t('orderFilterPackageTopup') },
        { value: 'sims', label: t('orderFilterPackageEsim') },
      ],
    },
  },
  {
    key: 'status',
    label: t('orderStatusesStatusTitle'),
    variant: 'CheckboxBadge',
    props: {
      options: [
        {
          value: 'COMPLETED',
          label: t('orderStatusesCompleted'),
          type: 'positive',
        },
        {
          value: 'REFUNDED',
          label: t('orderStatusesRefunded'),
          type: 'in_progress',
        },
        {
          value: 'FAILED',
          label: t('orderStatusesFailed'),
          type: 'negative',
        },
      ],
    },
  },
])

const searchQuery = ref('')
const selectedDates = ref<DateRange>()
const selectedDatesStr = computed(() => {
  return selectedDates.value
    ? `${selectedDates.value.start} - ${selectedDates.value.end}`
    : ''
})
const selectedFilters = ref()
const branchesFilter = ref()
const isPackageTypeSelected = (packageType: string) => {
  return selectedFilters.value?.packages?.includes(packageType) &&
    selectedFilters.value?.packages?.length < 2
    ? 'true'
    : undefined
}

const orderFilters = computed(() => ({
  filter: removeEmptyValues({
    code: searchQuery.value,
    created_at: selectedDatesStr.value,
    status: [
      ...(selectedFilters.value?.status ?? []),
      ...(selectedFilters.value?.payment_status ?? []),
    ].join(','),
    topups: isPackageTypeSelected('topups'),
    sims: isPackageTypeSelected('sims'),
    company_ids: [branchesFilter.value?.branches ?? []].join(','),
  }),
}))

const currentPage = ref(1)
const {
  data: apiOrderListResponse,
  fetchData: fetchApiOrderList,
  loading,
} = useApiData('/public-api/v1/orders', {
  transformer: transformApiOrderListResponse,
  immediate: true,
  params: () => ({
    ...orderFilters.value,
    page: currentPage.value,
    limit: 25,
  }),
  refetch: { watch: () => authStore.userCurrency },
})

const orderList = computed(() => apiOrderListResponse.value?.data ?? [])
const paginationData = computed(
  () => apiOrderListResponse.value?.pagination_info
)

const pageChanged = (page: number) => {
  currentPage.value = page
}

watch(orderFilters, () => {
  currentPage.value = 1
})

const fetchApiOrderListController = ref<AbortController | null>(null)
watch([orderFilters, currentPage], () => {
  if (fetchApiOrderListController.value) {
    fetchApiOrderListController.value.abort()
  }

  fetchApiOrderListController.value = new AbortController()
  fetchApiOrderList()
})
</script>
