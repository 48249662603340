<template>
  <div class="flex flex-col gap-4">
    <ManageBulkESimsShare
      v-if="shareBulkSIMs"
      :selectedBulkSims="selectedBulkSIMs"
      @hideBulkShare="hideBulkShare"
    />

    <ManageBulkESims
      v-else-if="showBulkSIMs"
      :selected-bulk-sims="selectedBulkSIMs"
      @hide-bulk-assign="hideBulkAssign"
      @show-bulk-share="showBulkShare"
      @fetch-esims="fetchSimList"
    />

    <template v-else>
      <ManageEsimsHeader
        v-model:search-query="searchQuery"
        v-model:filters="filters"
        v-model:branches-filter="branchesFilter"
        :selected-rows="selectedRows"
        :export-selected-limit="exportSelectedLimit"
        :export-all-limit="exportAllLimit"
        :loading="loading"
        :total-items="totalItems"
        data-testid="manageEsimsHeader"
        @show-bulk-assign="showBulkAssign"
        @show-bulk-share="showBulkShare"
      />

      <ManageEsimsTable
        v-model:selectedRows="selectedRows"
        v-model:page="currentPage"
        :rows="simList"
        :is-loading="loading"
        :total-items="totalItems"
        @patch-sim="handlePatchSim"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { cloneDeep } from 'lodash'
import { useStorage } from '@vueuse/core'
import { useApiData } from '@/composables/useApiData'
import { transformSimListResponse } from '@/api/transforms/simsTransforms'
import type { SimListItem } from '@/models/simModels'

definePage({
  name: 'manage-esims',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Sim\\Models\\Sim'],
  },
})

const router = useRouter()
const route = useRoute()
const searchQuery = ref('')
const currentPage = ref(1)
const showBulkSIMs = ref(false)
const shareBulkSIMs = ref(false)
const selectedBulkSIMs = ref<SimListItem[]>([])

const selectedRows = ref<[]>([])
const exportSelectedLimit = 10000
const exportAllLimit = 10000

const defaultFilters = {
  byAllocationStatus: '',
  byOrigin: '',
  attentionFilters: [],
}

const filters = ref(cloneDeep(defaultFilters))
const branchesFilter = ref()

const addBrandRedirectSelectedSim = useStorage<{ page: number }>(
  'addBrandRedirectSelectedSim',
  { page: 1 }
)
const addBrandRedirectSelectedSims = useStorage<SimListItem[]>(
  'addBrandRedirectSelectedSims',
  []
)
const checkRedirectFromAddBrand = () => {
  const { redirectToShareBulkEsims, redirectToShareEsim } = route?.query || {}

  if (redirectToShareBulkEsims) {
    shareBulkSIMs.value = true
    selectedBulkSIMs.value = addBrandRedirectSelectedSims.value
    addBrandRedirectSelectedSims.value = []
  } else if (redirectToShareEsim) {
    currentPage.value = addBrandRedirectSelectedSim.value.page
  }

  if (currentPage.value === 1) {
    fetchSimList()
  }
}

const checkICCIDWithQuery = () => {
  const iccid = route?.query?.iccid

  if (iccid) {
    searchQuery.value = iccid.toString()
    router.push('/manage-esims')
  }
}

const showBulkAssign = (esims: SimListItem[]) => {
  selectedRows.value = []
  selectedBulkSIMs.value = esims
  showBulkSIMs.value = true
}

const showBulkShare = (esims: SimListItem[]) => {
  selectedRows.value = []
  selectedBulkSIMs.value = esims
  shareBulkSIMs.value = true
}

const hideBulkAssign = () => {
  showBulkSIMs.value = false
}

const hideBulkShare = () => {
  shareBulkSIMs.value = false
}

const totalItems = ref<number>()
const {
  data: simListResponse,
  fetchData: fetchSimList,
  loading,
} = useApiData(`manage/v1/sims`, {
  transformer: transformSimListResponse,
  params: () => ({
    ...esimsFilters.value,
    page: currentPage.value,
    limit: 25,
  }),
  done: () => {
    totalItems.value = simListResponse.value?.meta?.total
  },
  immediate: false,
})

const esimsFilters = computed(() => ({
  filter: removeEmptyValues({
    search: searchQuery.value,
    type: filters?.value?.byAllocationStatus,
    origin: filters.value.byOrigin,
    attention: filters.value.attentionFilters,
    company_ids: (branchesFilter.value?.branches ?? []).join(','),
  }),
}))

const simList = computed(() => simListResponse.value?.data ?? [])

const handlePatchSim = (rowId: number, data: Partial<SimListItem>) => {
  const simListItem = simList.value.find(({ id }) => id === rowId)!
  simListItem.endUser = data.endUser
  simListItem.companyBrandId = data.companyBrandId
  simListItem.companyBrandAlias = data.companyBrandAlias
}

onMounted(() => {
  checkICCIDWithQuery()
  checkRedirectFromAddBrand()
})

watch(
  [searchQuery, filters, branchesFilter],
  () => {
    currentPage.value = 1
  },
  { deep: true }
)

watch(
  [searchQuery, filters, branchesFilter, currentPage],
  () => {
    fetchSimList()
  },
  { deep: true }
)
</script>
