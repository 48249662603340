<template>
  <BranchForm
    :createMode="true"
    :loading-branch-data="loadingBranchData"
    :branchSettings="branch"
    @submit="handleSubmitForm"
  >
    <template #header>
      <h3 class="text-trail-title1 text-trail-text-title-normal mb-6">
        {{ $t('components.nav.menu.new-branch') }}
      </h3>
    </template>
  </BranchForm>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useApiData } from '@/composables/useApiData'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import type {
  BranchSettings,
  NewBranchSettingsResponse,
} from '@/models/branchSettingModels'
import { useConfirmNavigationStore } from '@/stores/confirm-navigation'
import { onMounted, onUnmounted } from 'vue'

definePage({
  name: 'new-branch',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.companies.business_units.add'],
  },
})

const confirmNavigationStore = useConfirmNavigationStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { positive, negative } = useTrailToast()

onMounted(() => {
  confirmNavigationStore.setRequiresLeaveConfirmation(true)
})

onUnmounted(() => {
  confirmNavigationStore.setRequiresLeaveConfirmation(false)
})

const { data: branch, loading: loadingBranchData } = useApiData<BranchSettings>(
  `company/v1/hierarchies/${route.query.companyId}/branch`,
  {
    immediate: true,
  }
)

const {
  data: newBranchSettings,
  createData: createNewBranchSettings,
  error: newBranchSettingsError,
} = useApiData<NewBranchSettingsResponse>(
  `/company/v1/hierarchies/${route.query.companyId}/branch`,
  {
    immediate: false,
    removeEmptyValuesFromRequest: false,
  }
)

const handleSubmitForm = async (formData: Record<string, unknown>) => {
  await createNewBranchSettings(formData)

  if (newBranchSettings.value) {
    positive({
      content: t('branchFormSuccessCreateToast'),
    })
    confirmNavigationStore.setRequiresLeaveConfirmation(false)
    router.replace(`/company-settings/branches/`)
  } else if (newBranchSettingsError.value) {
    const errorMessage = Object.values(
      newBranchSettingsError.value?.response?.data.errors || [t('errorTitle')]
    ).join(' ')

    negative({
      content: errorMessage,
      timeout: 3000,
    })
  }
}
</script>
