<template>
  <div>
    <h3 class="text-trail-title1 text-trail-text-title-normal">
      {{ t('helpSupportPageTitle') }}
    </h3>

    <div
      class="grid grid-cols-[repeat(auto-fill,_minmax(19rem,_1fr))] gap-6 mt-6"
    >
      <Card
        v-for="(card, index) in helpCardData"
        :key="index"
        :title="t(card.title)"
        :description="t(card.description)"
        :icon="card.icon"
        :actions="card.actions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, onBeforeMount, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useScriptTag } from '@vueuse/core'
import { helpCardData } from '@/constant/helpData'
import { useInterfaceStore } from '@/stores/userInterface'
import { useBuyEsimStore } from '@/stores/buy-esim.store'

const buyEsimStore = useBuyEsimStore()
const userInterfaceStore = useInterfaceStore()

definePage({
  name: 'help',
  meta: { requiresAuth: true },
})

const { t } = useI18n({ useScope: 'global' })

onBeforeMount(() => {
  useScriptTag(
    'https://static.zdassets.com/ekr/snippet.js?key=eb0f026c-b531-4250-a349-d61966f84445',
    undefined,
    { attrs: { id: 'ze-snippet' } }
  )
  window.zESettings = {
    webWidget: {
      offset: {
        horizontal: '0',
        vertical: '60px',
      },
    },
  }
})

onMounted(() => {
  if (window.zE) {
    window.zE('webWidget', 'show')
  }
})

onUnmounted(() => {
  window.zE('webWidget', 'hide')
})

const isWidgetVisible = computed(
  () => !userInterfaceStore.sidebarOpened && !buyEsimStore.showShoppingBagModal
)

watch(isWidgetVisible, (newValue) => {
  if (window.zE) {
    window.zE('webWidget', newValue ? 'show' : 'hide')
  }
})
</script>
