<template>
  <Invoice />
</template>

<script setup lang="ts">
definePage({
  name: 'billing-details',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.finance.invoice.show'],
  },
})
</script>
