<template>
  <BranchForm
    :loading-branch-data="loadingBranchData"
    :branchSettings="branch"
    @submitUpdate="handleSubmitForm"
  >
    <template #header>
      <h3 class="text-trail-title1 text-trail-text-title-normal mb-6">
        {{ branch?.name }}
      </h3>
    </template>
  </BranchForm>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useApiData } from '@/composables/useApiData'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import type { NewBranchSettingsResponse } from '@/models/branchSettingModels'
import { computed, onMounted, onUnmounted } from 'vue'
import type { BranchSettings } from '@/models/branchSettingModels'
import { useConfirmNavigationStore } from '@/stores/confirm-navigation'

definePage({
  name: 'view-edit-branch',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.companies.business_units.show'],
  },
})

const confirmNavigationStore = useConfirmNavigationStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { positive, negative } = useTrailToast()
const routeParams = route.params as { id: string }
const branchId = computed(() => routeParams.id)

onMounted(() => {
  confirmNavigationStore.setRequiresLeaveConfirmation(true)
})

onUnmounted(() => {
  confirmNavigationStore.setRequiresLeaveConfirmation(false)
})

const { data: branch, loading: loadingBranchData } = useApiData<BranchSettings>(
  `company/v1/hierarchies/${branchId.value}/branch`,
  {
    immediate: true,
  }
)

const {
  data: updatedBranchSettings,
  updateData: updateBranchSettings,
  error: updateBranchSettingsError,
} = useApiData<NewBranchSettingsResponse>(
  `/company/v1/hierarchies/${route.query.companyId}/branch`,
  {
    immediate: false,
    removeEmptyValuesFromRequest: false,
  }
)

const handleSubmitForm = async (formData: Record<string, unknown>) => {
  await updateBranchSettings(formData)

  if (updatedBranchSettings.value) {
    positive({
      content: t('branchFormSuccessEditToast'),
    })
    confirmNavigationStore.setRequiresLeaveConfirmation(false)
    router.replace(`/company-settings/branches/`)
  } else if (updateBranchSettingsError.value) {
    const errorMessage = Object.values(
      updateBranchSettingsError.value?.response?.data.errors || [
        t('errorTitle'),
      ]
    ).join(' ')

    negative({
      content: errorMessage,
      timeout: 3000,
    })
  }
}
</script>
