<template>
  <div class="flex flex-col gap-4">
    <OrderPageHeader
      v-model:search-query="searchQuery"
      v-model:dates="selectedDates"
      v-model:filters="selectedFilters"
      v-model:branches-filter="branchesFilter"
      :order-page-title="t('orderPageTitle')"
      :filters-options="filtersOptions"
      :loading="loading"
      :pagination-data="paginationData"
      :selected-rows="selectedRows"
      :export-selected-limit="exportSelectedLimit"
      :export-all-limit="exportAllLimit"
      :order-filters="orderFilters"
      order-type="manual"
      data-testid="ordersPageHeader"
    />

    <TrailBanner
      v-if="selectedRows.length > exportSelectedLimit"
      data-testid="ordersSelectedLimitExceeded"
      show-icon
      type="warning"
    >
      {{ $t('exportSelectedOrdersLimit', { number: exportSelectedLimit }) }}
    </TrailBanner>

    <OrderTable
      v-model:selected-rows="selectedRows"
      :max-selected-rows="exportSelectedLimit"
      :rows="orderList"
      :is-loading="loading"
      :pagination="paginationData"
      order-type="manual"
      data-testid="ordersTable"
      @pagination-changed="pageChanged"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useApiData, removeEmptyValues } from '@/composables/useApiData'
import { transformOrderListResponse } from '@/api/transforms/ordersTransforms'
import { useAuthStore } from '@/stores/auth'
import type { TrailFilterOption } from '@/components/Trail/TrailFilter.vue'
import type { DateRange } from '@/components/Trail/TrailDateRangePicker.vue'

definePage({
  name: 'orders',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Package\\Models\\Hub\\Order'],
  },
})

const authStore = useAuthStore()
const { t } = useI18n()

const currentPage = ref(1)
const selectedRows = ref<[]>([])
const exportSelectedLimit = 15000
const exportAllLimit = 15000

const filtersOptions = computed<TrailFilterOption[]>(() => [
  {
    key: 'packages',
    label: t('orderFilterPackageTitle'),
    variant: 'CheckboxLabel',
    props: {
      options: [
        { value: 'topups', label: t('orderFilterPackageTopup') },
        { value: 'sims', label: t('orderFilterPackageEsim') },
      ],
    },
  },
  {
    key: 'status',
    label: t('orderStatusesStatusTitle'),
    variant: 'CheckboxBadge',
    props: {
      options: [
        {
          value: 'NEW',
          label: t('orderStatusesNew'),
          type: 'highlight',
        },
        {
          value: 'COMPLETED',
          label: t('orderStatusesCompleted'),
          type: 'positive',
        },
        {
          value: 'IN_PROGRESS',
          label: t('orderStatusesInProgress'),
          type: 'in_progress',
        },
        {
          value: 'CANCELLED',
          label: t('orderStatusesCancelled'),
          type: 'negative',
        },
        {
          value: 'PAYMENT_COMPLETED',
          label: t('orderStatusesPaymentCompleted'),
          type: 'positive',
        },
        {
          value: 'PAYMENT_PENDING',
          label: t('orderStatusesPaymentPending'),
          type: 'in_progress',
        },
        {
          value: 'PAYMENT_FAILED',
          label: t('orderStatusesPaymentFailed'),
          type: 'negative',
        },
        {
          value: 'PAYMENT_CANCELLED',
          label: t('orderStatusesPaymentCancelled'),
          type: 'negative',
        },
      ],
      showSearch: true,
      showSelectAll: true,
    },
  },
])

const searchQuery = ref('')
const selectedDates = ref<DateRange>()
const selectedDatesStr = computed(() => {
  return selectedDates.value
    ? `${selectedDates.value.start} - ${selectedDates.value.end}`
    : ''
})
const selectedFilters = ref()
const branchesFilter = ref()
const isPackageTypeSelected = (packageType: string) => {
  return selectedFilters.value?.packages?.includes(packageType) &&
    selectedFilters.value?.packages?.length < 2
    ? 'true'
    : undefined
}

const orderFilters = computed(() => ({
  filter: removeEmptyValues({
    code: searchQuery.value,
    created_at: selectedDatesStr.value,
    status: [
      ...(selectedFilters.value?.status ?? []),
      ...(selectedFilters.value?.payment_status ?? []),
    ].join(','),
    topups: isPackageTypeSelected('topups'),
    sims: isPackageTypeSelected('sims'),
    company_ids: [branchesFilter.value?.branches ?? []].join(','),
  }),
}))

const {
  data: orderListResponse,
  fetchData: fetchOrderList,
  loading,
} = useApiData('/store/v1/orders', {
  transformer: transformOrderListResponse,
  immediate: true,
  params: () => ({
    ...orderFilters.value,
    page: currentPage.value,
    limit: 25,
  }),
  refetch: { watch: () => authStore.userCurrency },
})

const orderList = computed(() => orderListResponse.value?.data ?? [])
const paginationData = computed(() => orderListResponse.value?.pagination_info)

const pageChanged = (page: number) => {
  currentPage.value = page
}

watch(orderFilters, () => {
  currentPage.value = 1
})

const fetchOrderListController = ref<AbortController | null>(null)
watch([orderFilters, currentPage], () => {
  if (fetchOrderListController.value) {
    fetchOrderListController.value.abort()
  }

  fetchOrderListController.value = new AbortController()
  fetchOrderList()
})
</script>
