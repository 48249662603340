<template>
  <div
    class="max-w-500 my-12 md:my-18 lg:my-24 px-2 lg:w-1/3"
    data-testid="brand-step-page"
  >
    <BrandStep data-testid="brand-step" />
  </div>
</template>

<script lang="ts" setup>
definePage({
  name: 'onboarding-brand',
  meta: { requiresAuth: false },
  path: '/sign-up/onboarding/brand',
})
</script>
