<template>
  <div class="relative bg-white overflow-hidden" data-testid="landing-page">
    <LandingHeaderBar
      class="sticky top-0 z-50"
      data-testid="header-bar"
      type="partnerships-business"
    />

    <Hero />

    <ValuePropositionTrustedBy
      :img-list="trustedByImageList"
      :img-width="100"
      :img-height="30"
      :animation-time="15"
      class="!bg-transparent sm:!mb-24"
    />

    <InfoSection />

    <LearningSection />

    <TakeActionCard />

    <Footer />
  </div>
</template>

<script setup lang="ts">
definePage({
  meta: { requiresAuth: false },
  name: 'partnerships-business',
})

import type { TImageProps } from '@/components/ValueProposition/ValuePropositionTrustedBy.vue'

const trustedByImageList = computed<TImageProps[]>(() => {
  const images = Object.keys(
    import.meta.glob([
      '#root/public/partners/trusted-by/*.png',
      '#root/public/partners/trusted-by/*.svg',
    ])
  ).map((img, index) => ({
    img: img.replace('/public', ''),
    alt: `img ${index + 1}`,
    position: index + 1,
  }))

  return images
})
</script>
